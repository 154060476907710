<template>
  <div
      class="modal fade"
      id="appointmentRegisteredAddressCreateModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="appointmentRegisteredAddressCreateModal"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Address Create</h1>
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <p>
            Selected Technician State:
            {{ selectedState ? selectedState.name : "None" }}
          </p>

          <form @submit.prevent="saveAddress">
            <div class="address_input_container">

              <label for="address">Add New Address</label>
              <input
                  id="address"
                  type="text"
                  v-model="address"
                  @input="fetchPlaces"
                  @keydown.down.prevent="highlightNext"
                  @keydown.up.prevent="highlightPrevious"
                  @keydown.enter.prevent="selectHighlighted"
                  placeholder="Search address"
              />

              <ol v-if="suggestions.length" class="suggestions">
                <li
                    v-for="(suggestion, index) in suggestions"
                    :key="suggestion.place_id"
                    :class="{ highlighted: index === highlightedIndex }"
                    @click="selectPlace(suggestion)"
                >
                  {{ suggestion.description }}
                </li>
              </ol>
            </div>

            <button type="submit" class="saveBtn btn btn-primary mt-1">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Declare `google` as a global variable to avoid ESLint errors
/* global google */
import toggleModal from "@/components/backEnd/mixins/ToggleModal";

export default {
  props: {
    selectedState: Object,
    rowIndex: Number,
  },
  mixins: [toggleModal],
  data() {
    return {
      address: "",
      suggestions: [],
      autocompleteService: null,
      placesService: null,
      sessionToken: null,
      highlightedIndex: -1,
    };
  },
  mounted() {
    if (!this.autocompleteService) {
      this.autocompleteService = new google.maps.places.AutocompleteService();
    }
    if (!this.placesService) {
      this.placesService = new google.maps.places.PlacesService(document.createElement("div"));
    }
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  },
  methods: {
    fetchPlaces() {
      if (!this.address) {
        this.suggestions = [];
        return;
      }

      this.autocompleteService.getPlacePredictions(
          {
            input: this.address,
            componentRestrictions: {country: "AU"},
            sessionToken: this.sessionToken,
          },
          (predictions, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
              this.suggestions = [];
              return;
            }

            // Filter predictions by state
            const filteredPredictions = [];
            let processed = 0;

            predictions.forEach((prediction) => {
              this.placesService.getDetails(
                  {placeId: prediction.place_id, fields: ["address_components", "formatted_address"]},
                  (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK && place) {
                      const stateComponent = place.address_components.find(comp =>
                          comp.types.includes("administrative_area_level_1")
                      );

                      if (stateComponent && stateComponent.long_name === this.selectedState.name) {
                        filteredPredictions.push(prediction);
                      }
                    }
                    processed++;

                    // Once all are processed, update suggestions
                    if (processed === predictions.length) {
                      this.suggestions = filteredPredictions;
                    }
                  }
              );
            });
          }
      );
    },

    selectPlace(suggestion) {
      this.address = suggestion.description;
      this.suggestions = []; // Hide suggestions
    },

    highlightNext() {
      if (this.highlightedIndex < this.suggestions.length - 1) {
        this.highlightedIndex++;
      }
    },

    highlightPrevious() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex--;
      }
    },

    selectHighlighted() {
      if (this.highlightedIndex !== -1) {
        this.selectPlace(this.suggestions[this.highlightedIndex]);
      }
    },

    saveAddress() {
      if (!this.address) {
        alert("Please select a valid address from the suggestions.");
        return;
      }

      this.$emit("address-selected", {address: this.address, rowIndex: this.rowIndex});
      this.address = '';
      this.toggleModal('appointmentRegisteredAddressCreateModal')
    },
  },
};
</script>


<style scoped>
form {
  width: 100%;
}

.address_input_container {
  width: 100%;
  position: relative;
}

.address_input_container input {
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gainsboro;
}

.suggestions {
  list-style: none;
  background: white;
  border: 1px solid #ddd;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li:hover,
.suggestions .highlighted {
  background-color: #f0f0f0;
}
</style>