<template>
  <div class="container">
    <div class="add-row-container">
      <button @click="addRow" class="btn btn-primary">+ Add Row</button>
      <button @click="updateSettings" class="btn btn-primary">Update</button>
    </div>

    <!-- Rows Section -->
    <div v-for="(row, index) in rows" :key="index" class="row_section">
      <div class="row_container">
        <div class="row_container_content">
          <div class="dropdown_container">
            <label>State</label>
            <VueMultiselect
                v-model="row.selectedOption"
                :options="options"
                :close-on-select="true"
                label="name"
                track-by="value"
                :show-labels="false"
                :allow-empty="false"
            />
          </div>

          <div class="address_container">

            <div class="address_text">
              <div class="d-flex justify-content-between align-items-center">
                <label>ADDRESS</label>
                <div class="open_modal_btn">
                  <button
                      @click="showAddressAddModal(index)"
                      class="btn btn-primary"
                  >
                    +
                  </button>
                </div>
              </div>
              <ol>
                <li v-for="(addressObj, addressIndex) in row.selectedAddresses" :key="addressIndex">
                  <div class="d-flex align-items-center">
                    <strong class="text-uppercase">{{ addressObj.state }}:</strong> {{ addressObj.address }}
                    <button @click="deleteAddress(index, addressIndex)" class="delete-address-btn">
                      <i class="bx bx-x"></i>
                    </button>
                  </div>
                </li>
              </ol>
              <div v-if="row.selectedAddresses.length === 0">
                No addresses selected.
              </div>
            </div>
          </div>
        </div>

        <button @click="deleteRow(index)" class="delete_btn">
          <i class="bx bx-x"></i>
        </button>
      </div>
    </div>

    <AppointmentRegisteredAddressModal
        :selected-state="selectedStateOption"
        :available-state-data="availableOptions"
        :row-index="selectedModalRow"
        @address-selected="saveAddress"
    />
    <div class="" data-toggle="modal" data-target="#appointmentRegisteredAddressCreateModal"></div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppointmentRegisteredAddressModal
  from "@/views/backEnd/settings/appointments/includes/AppointmentRegisteredAddressModal.vue";
import toggleModal from "@/components/backEnd/mixins/ToggleModal";

export default {
  name: "SettingAppointmentRegisteredAddress",
  components: {AppointmentRegisteredAddressModal},
  mixins: [ShowToastMessage, Loader, toggleModal],
  props: ["appointmentRegisteredAddress", "defaultState"],
  data() {
    return {
      currentSetting: this.appointmentRegisteredAddress,
      checkBoxStatus: 0,
      selectedModalRow: null,
      rows: [],
      selectedStateOption: {}
    };
  },

  computed: {
    isUpdateButtonEnable() {
      return (
          this.currentSetting.value === "" || this.currentSetting.value === null
      );
    },

    options() {
      return this.defaultState?.value || [];
    },

    availableOptions() {
      const selectedOptions = this.rows.map((row) => row.selectedOption);
      return this.options.filter((option) => !selectedOptions.includes(option));
    },
  },

  watch: {
    'appointmentRegisteredAddress': {
      handler(newValue) {
        this.rows = newValue.value.map((item) => ({
          selectedOption: this.defaultState?.value.find(state => state.value === item.state) || null,
          selectedAddresses: item.addresses.map(address => ({state: item.state, address})),
        }));
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    addRow() {
      this.rows.push({
        selectedOption: this.options.length ? this.options[0] : null,
        selectedAddresses: [],
      });
    },

    deleteRow(index) {
      this.rows.splice(index, 1);
    },

    showAddressAddModal(index) {
      this.selectedModalRow = index;
      this.selectedStateOption = this.rows[index].selectedOption
      this.toggleModal('appointmentRegisteredAddressCreateModal')
    },

    saveAddress({address, rowIndex}) {
      if (rowIndex !== null && this.rows[rowIndex]) {
        this.rows[rowIndex].selectedAddresses.push({
          state: this.rows[rowIndex].selectedOption.value,
          address: address,
        });
      }
      this.selectedModalRow = null;
    },

    deleteAddress(rowIndex, addressIndex) {
      this.rows[rowIndex].selectedAddresses.splice(addressIndex, 1);
    },

    async updateSettings() {
      let formattedAddresses = this.rows.map(row => ({
        state: row.selectedOption.value,
        addresses: row.selectedAddresses.map(addr => addr.address)
      }));

      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: formattedAddresses,
        }
      };

      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment registered address updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  padding: 10px 20px;
  position: relative;
}

.state_section_container {
  border-bottom: 1px solid gainsboro;
}

.add-row-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.row_section {
  border: 1px solid gainsboro;
  border-radius: 10px;
  margin: 20px 50px;
}

.row_container {
  width: 100%;
  position: relative;
}

.row_container_content {
  display: grid;
  grid-template-columns: 30% 70%;
}

.dropdown_container {
  width: 100%;
  border-right: 1px solid gainsboro;
  padding: 10px 20px;
}

.dropdown {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.address_container {
  padding: 10px 20px 20px 20px;
}

.open_modal_btn {
  /* display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px; */
  margin-bottom: 10px;
}

.open_modal_btn button {
  padding: 0px 20px;
  font-size: 18px;
}

.address_text {
  flex: 1;
  font-size: 14px;
  color: #333;
}

.delete_btn {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: absolute;
  margin-left: 98%;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: 1px solid gainsboro;
  color: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.btn {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #28a745;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

ol {
  padding: 10px 0px 0px 18px;
  border-top: 1px solid gainsboro;
}

li {
  line-height: 30px;
  font-size: 15px;
}

.delete-address-btn {
  background: none;
  border: 1px solid gainsboro;
  border-radius: 5px;
  height: 25px;
  color: red;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.delete-address-btn:hover {
  color: darkred;
}
</style>
